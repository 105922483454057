<template>
  <div>
    <vx-card class="mwb-filter-card">
      <vs-row vs-w="12">
        <vs-col vs-w="9" vs-type="flex" vs-justify="flex-start" class="no-padd">
          <!-- <vx-card > -->
          <div style="width:100%">
            <vs-row>
              <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" class="desc-border-box no-padd">
                <p>
                  Eligibility <span>{{ countsleads }}</span>
                </p>
              </vs-col>
            </vs-row>

            <vs-row class="mb-5 mt-6" vs-w="12">
              <vs-col vs-w="1" vs-offset="0.3" vs-type="flex" vs-justify="flex-start" vs-align="center"
                style="margin-top:10px;">
                <h6 class="ele-side-head filter">Filters</h6>
              </vs-col>
              <vs-col vs-type="flex" vs-offset="0.3" vs-justify="center" vs-align="center" vs-w="2" class="tree-drop" id="team">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                    Team
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <v-select class=" invc-input" v-model="initSelectedTeam" :options="treeDataTeam">
                  </v-select>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="tree-drop" id="city">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                    City
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                    <treeselect v-model="initSelectedCity" :multiple="true" :options="treeDataCity" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-offset="0.1" vs-w="2" class="tree-drop"
                id="cc">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox" style="text-align: left;">
                    City Classification
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="customDropDown">
                    <treeselect v-model="initSelectedCC" :multiple="true" :options="treeDataCC" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-offset="0.1" vs-w="2" class="tree-drop"
                id="spoc">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                    SPOC
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="customDropDown">
                    <treeselect :value-consists-of="'LEAF_PRIORITY'" v-model="initSelectedSpoc" :multiple="true"
                      :options="treeDataSpoc" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-offset="0.1" vs-w="2" class="tree-drop"
                id="sc">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox" style="text-align: left;">
                    SPOC Classcification
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="customDropDown">
                    <treeselect v-model="initSelectedSC" :multiple="true" :options="treeDataSC" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
            </vs-row>
            <vs-row class="mt-8">

              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-offset='1.7' vs-w="2" class="tree-drop"
                id="levels">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                    CM Level
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="dropdown-login customDropDown" style="padding: 0px">
                    <treeselect v-model="initSelectedLevels" :multiple="true" :options="treeDataLevels" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-offset='0.2' vs-w="2" class="tree-drop"
                id="course">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                    Course
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="customDropDown">
                    <treeselect v-model="initCourses" :multiple="true" :options="treeDataCourses" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-offset='0.2' vs-w="3" class="tree-drop"
                id="elgibility_status">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                    Eligibility Status
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="customDropDown">
                    <treeselect v-model="initSelectedeligibiltiy" :multiple="true" :options="treeDataeligibility" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
            </vs-row>

            <!-- <vs-row class="mt-8">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-offset="0.3"
                vs-w="3.5"
                id="card2"
                class="tree-drop"
              >
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a
                    class="a-icon a-iconn eligibility-inside-text"
                    href.prevent
                    @click="showTreeBox"
                  >
                    Eligibility Status
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu
                    class="dropdown-login customDropDown"
                    style="padding: 0px"
                  >
                    <treeselect
                      v-model="initSelectedeligibiltiy"
                      :multiple="true"
                      :options="treeDataeligibility"
                    />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
            </vs-row> -->

            <vs-row class="mb-5 mt-6" vs-w="12">
              <vs-col vs-w="1.3" vs-offset="0.3" vs-type="flex" vs-justify="flex-start" vs-align="center"
                style="margin-top:10px;">
                <h6 class="ele-side-head filter"> Persona Filters</h6>
              </vs-col>
              <!-- <vs-col vs-w="10" vs-type="flex" vs-justify="space-around" class="mb-4"></vs-col> -->
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="tree-drop" id="city">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                    UG
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="customDropDown qualification" style="z-index: 60000;">
                    <treeselect v-model="selected_ug" :multiple="true" :options="ug_options" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-offset="0.2" vs-w="2" class="tree-drop"
                id="spoc">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                    PG
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="customDropDown qualification">
                    <treeselect v-model="selected_pg" :multiple="true" :options="pg_options" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-offset="0.2" vs-w="2.7" class="tree-drop"
                id="cc">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox" style="text-align: left;">
                    Indian Qualification
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="customDropDown">
                    <treeselect v-model="selected_indian_prof" :multiple="true"
                      :options="indian_professional_qualifications" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>

              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-offset="0.2" vs-w="2.7" class="tree-drop"
                id="sc">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox" style="text-align: left;">
                    Global Qualification
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="customDropDown">
                    <treeselect v-model="selected_global_prof" :multiple="true"
                      :options="global_professional_qualifications" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
            </vs-row>

            <!-- <vs-row class="mt-8">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-offset='1.6' vs-w="2" class="tree-drop"
                id="elgibility_status">
                <vs-dropdown vs-custom-content vs-trigger-click>
                  <a class="a-icon a-iconn ele-inside-text" href.prevent @click="showTreeBox">
                    Pathway
                    <i class="material-icons">expand_more</i>
                  </a>
                  <vs-dropdown-menu class="customDropDown pathway_dropdown" style="z-index: 60000;">
                    <treeselect v-model="select_pathway" :multiple="true" :options="pathway_options" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
            </vs-row> -->

            <vs-row class="my-8">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.3" vs-w="1.3" id="card2">
                <h6 class="ele-side-head">Date Filter</h6>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-offset="0.1"
                vs-w="2.3">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading">Next Call</label>
                  <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                      firstDay: 1,
                      format: 'DD-MMM-YYYY',
                    }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                    :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                    v-model="nextCallDateRange" class="eligibility">
                  </date-range-picker>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-offset="0.1"
                vs-w="2.3">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading">Last Call</label>
                  <date-range-picker ref="picker" :opens="'center'" :locale-data="{
                      firstDay: 1,
                      format: 'DD-MMM-YYYY',
                    }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                    :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                    placeholder="DD/MM/YY" v-model="lastCallDateateRange" class="eligibility">
                  </date-range-picker>
                </div>
              </vs-col>
              <!-- <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="2.3"
              >
                <div>
                  <label class="labell">Eligibility month and year</label>
                  <datepicker
                    placeholder="MM/YY"
                    :minimumView="'month'"
                    :maximumView="'month'"
                    class="my_date_picker"
                    :format="customFormatter"
                    v-model="dates"
                    style="width:187px;"
                  ></datepicker>
                </div>
              </vs-col> -->
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-offset="0.1"
                vs-w="2.3">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading">Eligibility month and year</label>
                  <datepicker placeholder="MM/YY" :minimumView="'month'" :maximumView="'month'" class="visit_date_picker"
                    :format="customFormatter" v-model="dates"></datepicker>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-offset="0.1"
                vs-w="2.3">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading">Enrollment month and year</label>
                  <datepicker placeholder="MM/YY" :minimumView="'month'" :maximumView="'month'" class="visit_date_picker"
                    :format="customFormatter" v-model="enrollment_dates"></datepicker>
                </div>
              </vs-col>
            </vs-row>

            <vs-row class="my-3">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-1" vs-offset="1.7"
                vs-w="2.3">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading">Enrollment Date Range</label>
                  <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                      firstDay: 1,
                      format: 'DD-MMM-YYYY',
                    }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                    :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                    v-model="entrollmentDateRange" class="eligibility">
                  </date-range-picker>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="0.4" style="margin-top: 15px;">
                <div>
                  <vs-button color="#0044ba" icon="clear" @click="clearDateFilter" size="small"></vs-button>
                </div>
              </vs-col>
            </vs-row>

            <vs-row class="my-3">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.3" vs-w="11.7">
                <vs-row class="criteria mb-4">
                  <vs-row>
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.1" vs-w="11.7">
                      <h6 class="criteria_heading">Search Criteria:</h6>
                    </vs-col>
                  </vs-row>
                  <vs-row vs-w="12">
                    <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.1" vs-w="10.7">
                      <div style="display: block; width: 100%">
                        <!-- <div v-for="(chip, index) in searchCriteria" :key="chip">
                      <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable @click="removechip(chip)" :color="cc_chip_color">
                        <b>{{ chip }}</b>
                      </vs-chip>
                    </div> -->
                        <span v-for="chip in teamData" :key="chip">
                          <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                            @click="removechip(chip, 'team')" color="#caecc5">
                            <b>{{ chip }}</b>
                          </vs-chip>
                        </span>
                        <span v-for="chip in cityData" :key="chip">
                          <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                            @click="removechip(chip, 'city')" color="#EAE5FA">
                            <b>{{ chip }}</b>
                          </vs-chip>
                        </span>
                        <span v-for="chip in selectedCCData" :key="chip">
                          <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                            @click="removechip(chip, 'cc')" color="#FAE1FE">
                            <b>{{ chip }}</b>
                          </vs-chip>
                        </span>
                        <span v-for="chip in selectedSpocData" :key="chip">
                          <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                            @click="removechip(chip, 'spoc')" color="#BCEBFA">
                            <b>{{ chip }}</b>
                          </vs-chip>
                        </span>
                        <span v-for="chip in selectedSCData" :key="chip">
                          <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                            @click="removechip(chip, 'sc')" color="#B4EDFF">
                            <b>{{ chip }}</b>
                          </vs-chip>
                        </span>

                        <span v-for="chip in selectedCourseData" :key="chip">
                          <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                            @click="removechip(chip, 'course')" color="#FFC2C2">
                            <b>{{ chip }}</b>
                          </vs-chip>
                        </span>
                        <span v-for="chip in selectedEligibilityData" :key="chip">
                          <vs-chip v-if="chip !== 'divider'" class="criteria_chip" closable
                            @click="removechip(chip, 'eligibility')" color="#f1ad78">
                            <b>{{ chip }}</b>
                          </vs-chip>
                        </span>
                        <span v-for="level in selectedLevels" :key="level">
                          <vs-chip v-if="level !== 'divider'" class="criteria_chip" closable
                            @click="removechip(level, 'levels')" color="#F5E1CE">
                            <b>{{ level }}</b>
                          </vs-chip>
                        </span>
                        <span v-for="ug in selected_ug_data" :key="ug">
                          <vs-chip v-if="ug !== 'divider'" class="criteria_chip" closable @click="removechip(ug, 'ug')"
                            color="#EAE5FA">
                            <b>{{ ug }}</b>
                          </vs-chip>
                        </span>
                        <span v-for="pg in selected_pg_data" :key="pg">
                          <vs-chip v-if="pg !== 'divider'" class="criteria_chip" closable @click="removechip(pg, 'pg')"
                            color="#BCEBFA">
                            <b>{{ pg }}</b>
                          </vs-chip>
                        </span>
                        <span v-for="indian_data in selected_indian_data" :key="indian_data">
                          <vs-chip v-if="indian_data !== 'divider'" class="criteria_chip" closable
                            @click="removechip(indian_data, 'indian_data')" color="#FAE1FE">
                            <b>{{ indian_data }}</b>
                          </vs-chip>
                        </span>
                        <span v-for="global_data in selected_global_data" :key="global_data">
                          <vs-chip v-if="global_data !== 'divider'" class="criteria_chip" closable
                            @click="removechip(global_data, 'global_data')" color="#B4EDFF">
                            <b>{{ global_data }}</b>
                          </vs-chip>
                        </span>
                        <span v-for="pathway in selected_pathway_data" :key="pathway">
                          <vs-chip v-if="pathway !== 'divider'" class="criteria_chip" closable
                            @click="removechip(pathway, 'pathway')" color="#f1ad78">
                            <b>{{ pathway }}</b>
                          </vs-chip>
                        </span>
                      </div>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="1.2">
                      <vs-button color="#0044ba" icon="search" @click="getEligibilityDetails(1)" size="small"></vs-button>
                      &nbsp;
                      <vs-button color="#0044ba" icon="refresh" @click="refreshInputData" size="small"></vs-button>
                    </vs-col>
                  </vs-row>
                </vs-row>
              </vs-col>
            </vs-row>

            <!-- </vx-card> -->
          </div>
        </vs-col>

        <vs-col vs-w="2.7" vs-type="flex" class="no-padd" style="height: fit-content;">
          <vx-card class="m-6 mwb-filter-card">
            <p class="option-side-head my-2">Options:</p>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 mx-2">
                <!-- <vs-input icon-no-border iconAfter="true"  icon="search" placeholder="Can-ID"
                v-model="Can_id" class="input-filter mb-2 mx-2 " @click="Details()" /> -->
                <vs-input placeholder="Can-ID" v-model="Can_id" class="input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="getEligibilityDetails(1)"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 mx-2">
                <!-- <vs-input icon-no-border iconAfter="true" icon="search" placeholder="Candidate Name" v-model="candidate_name"
               class="input-filter mb-2 mx-2" /> -->
                <vs-input placeholder="Candidate Name" v-model="candidate_name" class="input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="getEligibilityDetails(1)"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 mx-2">
                <!-- <vs-input icon-no-border iconAfter="true" icon="search" placeholder="Company" v-model="company"
              class="input-filter mb-2 mx-2" /> -->
                <vs-input placeholder="Company" v-model="company" class="input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="getEligibilityDetails(1)"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 mx-2">
                <!-- <vs-input icon-no-border iconAfter="true" icon="search" placeholder="Contact Number" v-model="contact_num"
               class="input-filter mb-2 mx-2" /> -->
                <vs-input placeholder="Contact Number" v-model="contact_num" class="input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="getEligibilityDetails(1)"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 mx-2">
                <!-- <vs-input icon-no-border iconAfter="true" icon="search" placeholder="Email Address" v-model="email"
               class="input-filter mb-2 mx-2" /> -->
                <vs-input placeholder="Email Address" v-model="email" class="input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="getEligibilityDetails(1)"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 mx-2">
                <!-- <vs-input icon-no-border iconAfter="true" icon="search" placeholder="Eligibility Code"
             v-model="eligibility_code" class="input-filter mb-2 mx-2" /> -->
                <vs-input placeholder="Eligibility Code" v-model="eligibility_code" class="input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="getEligibilityDetails(1)"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-3">
              <vs-col vs-type="flex" vs-justify="center">
                <vs-row vs-w="12" style="margin-top: 4px">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-offset="0.3" vs-w="5.7"
                    style="padding: 0px !important">
                    <v-select :clearable="true" :filterable="false" class="credit_operator" :options="operator"
                      v-model="value6" style="width: 100%;" />
                  </vs-col>
                  <vs-col vs-type="flex" vs-justify="flex-end" vs-offset="0.3" vs-align="center" vs-w="5.5"
                    style="padding: 0px !important">
                    <vs-input icon-no-border placeholder="Credits" v-model="value7" class="credit_input"
                      style="margin-bottom: 3px !important; border-radius:22px;" />
                  </vs-col>
                </vs-row>
              </vs-col>
            </vs-row>
            <vs-row class="mb-3">
              <vs-col vs-type="flex" vs-justify="center" class="mb-2">
                <vs-button color="#0044BA" icon="search" size="small" @click="getEligibilityDetails(1)">Search</vs-button>
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>
      </vs-row>
    </vx-card>
    <div class="mt-3">
      <eligibility-tabulator :tabulatorDetails="tabulatorDetails" @download-eligibility-report="downloadReport()" />
      <div v-if="tabulatorDetails.length == 0" style="color: grey; margin: 5px">
        <center>NO DATA AVAILABLE</center>
      </div>
    </div>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
        <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
      </vs-col>
    </vs-row>
    <vs-popup class="holamundo download-report" title="" :active.sync="download_report_success_popup">
      <div class="popup-box">
        <h5>Download Success <vs-icon icon="schedule_send" size="22px" color="#91C11E" style="margin-left:12px"></vs-icon>
        </h5>
        <p>Eligibility Report will be sent to your mail shortly</p>
        <!-- <span>x</span> -->
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import moment from "moment";
import "vue-tree-halower/dist/halower-tree.min.css";
import LeadsNavBar from "../components/pagesComponents/LeadsNavBar.vue";
import FocusSearch from "../components/pagesComponents/FocusSearch.vue";
import SpocSearchTree from "../components/pagesComponents/SPOCSearchTree.vue";
import Datepicker from "vuejs-datepicker";
import EventBus from "../components/eventbus.js";
import DateRangePicker from "vue2-daterange-picker";
import EligibilityTabulator from "./MWBEligibilityTabulator.vue";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { VTree, VSelectTree } from "vue-tree-halower";
import axios from "axios";
import constants from "../../constants.json";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    LeadsNavBar,
    // LeadsDataTable,
    VTree,
    VSelectTree,
    DateRangePicker,
    FocusSearch,
    SpocSearchTree,
    EligibilityTabulator,
    Treeselect,
    "v-select": vSelect,
  },
  data() {
    return {
      treeDataTeam: [],
      initSelectedTeam: 'CM',
      teamData: ['CM'],
      user_email: "",
      download_report_success_popup: false,
      dropdownKey: 0,
      SpocPrefilled: "",
      canIdWidth: "100px",
      tabulatorDetails: [],
      spoc_ids: [],
      spocLogged: false,
      initSelectedLevels: [],
      initSelected: undefined,
      initSelectedReport: [],
      initSelectedeligibiltiy: [],
      merge_city: [],
      city_all_selected: "",
      Can_id: null,
      candidate_name: null,
      company: null,
      contact_num: null,
      email: null,
      cityData: [],
      api_city_data: [],
      filter_data: [],
      city_wise_data: [],
      ug_options: [],
      pg_options: [],
      indian_professional_qualifications: [],
      global_professional_qualifications: [],
      selected_indian_prof: [],
      selected_global_prof: [],
      selected_ug: [],
      selected_pg: [],
      selected_ug_data: [],
      selected_pg_data: [],
      selected_indian_data: [],
      selected_global_data: [],
      dates: null,
      entrollmentDateRange: {
        startDate: null,
        endDate: null,
      },
      enrollment_dates: null,
      selected_pathway_data: [],
      treeDatafocus: [
        // {
        //   id: "All",
        //   label: "All",
        //   children: [
        {
          id: "delays",
          label: "Delays",
        },
        {
          id: "today",
          label: "Today",
        },
        {
          id: "waiting",
          label: "Waiting",
        },
        {
          id: "untapped",
          label: "Untapped",
        },
        {
          id: "mhp",
          label: "Mhp",
        },
        // ]
        // }
      ],
      select_pathway: [],
      pathway_options: [
        {
          id: "Pathway 1 - India",
          label: "Pathway 1 - India",
        },
        {
          id: "Pathway 2 - US",
          label: "Pathway 2 - US",
        },
        {
          id: "Pathway 3 - Canada",
          label: "Pathway 3 - Canada",
        },
        {
          id: "Pathway 4 - US or Canada",
          label: "Pathway 4 - US or Canada",
        },
        {
          id: "Pathway 5 - India/US/Canada",
          label: "Pathway 5 - India/US/Canada",
        },
      ],
      treeDataReportSent: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "Not Sent",
              label: "Not Sent",
            },
            {
              id: "Sent",
              label: "Sent",
            },
          ],
        },
      ],
      treeDataeligibility: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "Not eligible",
              label: "Not eligible",
            },
            {
              id: "Eligible For CPA Exams",
              label: "Eligible For CPA Exams",
            },
            {
              id: "Eligible For CPA Exams & License",
              label: "Eligible For CPA Exams & License",
            },
          ],
          //   children: [
          // {
          //   id: "Check",
          //   label: "Check",
          // },
          // {
          //   id: "Clear",
          //   label: "Clear",
          // },
          // {
          //   id: "Done",
          //   label: "Done",
          // },
          // {
          //   id: "Eligibility",
          //   label: "Eligibility",
          // },
          // {
          //   id: "No",
          //   label: "No",
          // },
          // {
          //   id: "Pending",
          //   label: "Pending",
          // },
          // {
          //   id: "PGDA",
          //   label: "PGDA",
          // },
          // {
          //   id: "PGDPA",
          //   label: "PGDPA",
          // },
          // {
          //   id: "Unclear",
          //   label: "Unclear",
          // },
          // {
          //   id: "YES",
          //   label: "YES",
          // },
          //   ]
        },
      ],
      selecteddatebool: false,
      eligibility: "",
      lang: {
        placeholder: {
          date: "Next Contact",
          dateRange: "Select a date range",
        },
      },
      dateRange: {
        startDate: "",
        endDate: "",
      },
      lastCallDateateRange: {
        startDate: null,
        endDate: null,
      },
      nextCallDateRange: {
        startDate: null,
        endDate: null,
      },
      nameAutocomplete: [],
      initSelectedBDE: [],
      years: [],
      initSelectedCourses: [],
      initCourses: [],
      eligibility_months: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      month_numbers: {
        January: "01",
        February: "02",
        March: "03",
        April: "04",
        May: "05",
        June: "06",
        July: "07",
        August: "08",
        September: "09",
        October: "10",
        November: "11",
        December: "12",
      },
      selected_month: "",
      selected_eligibility_year: null,
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      treeDataCity: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedCity: [],
      treeDataCC: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedCC: [],
      selectedCCData: [],
      selectedSpocData: [],
      selectedSCData: [],
      selectedCourseData: [],
      selectedEligibilityData: [],
      treeDataSpoc: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedSpoc: [],
      initSelectedSC: [],
      selectedLevels: [],
      treeDataSC: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      countsleads: 0,
      treeDataCourses: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "CPA",
              label: "CPA",
            },
            {
              id: "CPA-AA",
              label: "CPA-AA",
            },
            {
              id: "USP",
              label: "USP",
            },
            // {
            //   id: "AICPA",
            //   label: "AICPA",
            // },
            // {
            //   id: "CISA",
            //   label: "CISA",
            // },
            // {
            //   id: "EA",
            //   label: "EA",
            // },
            // {
            //   id: "CIA",
            //   label: "CIA",
            // },
            {
              id: "CMA",
              label: "CMA",
            },
            {
              id: "CFA",
              label: "CFA",
            },
            {
              id: "FRM",
              label: "FRM",
            },
            // {
            //   id: "RPO",
            //   label: "RPO"
            // },
            // {
            //   id: "IIML-FA",
            //   label: "IIML-FA",
            // },
            // {
            //   id: "IIML-FT",
            //   label: "IIML-FT",
            // },
            // {
            //   id: "IIML-SF",
            //   label: "IIML-SF",
            // },
            // {
            //   id: "IIML-DA",
            //   label: "IIML-DA",
            // },
            // {
            //   id: "IIML-HR",
            //   label: "IIML-HR",
            // },
            // {
            //   id: "IITR-BF",
            //   label: "IITR-BF",
            // },
            // { id: "IITR-DB", label: "IITR-DB" },
            // { id: "IITM-AA", label: "IITM-AA" },
            // { id: "IIMK-CX", label: "IIMK-CX" },
            // { id: "IITM-FS", label: "IITM-FS" },
            // { id: "IITR-CC", label: "IITR-CC" },
            // { id: "IIMK-FT", label: "IIMK-FT" },
            // { id: "IIML-AB", label: "IIML-AB" },
            // { id: "IIML-SH", label: "IIML-SH" },
            // { id: "IITJ-DE", label: "IITJ-DE" },
            // { id: "XLRI-HR", label: "XLRI-HR" },
            // { id: "XLRI-SH", label: "XLRI-SH" },
            // { id: "IIMI-BA", label: "IIMI-BA" },
            // { id: "IIMI-AA", label: "IIMI-AA" },
            // { id: "XLRI-DM", label: "XLRI-DM" },
            // {
            //   id: "IIML-PM",
            //   label: "IIML-PM",
            // },
          ],
        },
      ],
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3",
                  label: "M3",
                },
                {
                  id: "M3+",
                  label: "M3+",
                },
                {
                  id: "M3++",
                  label: "M3++",
                },
                {
                  id: "M5",
                  label: "M5",
                },
                {
                  id: "M6",
                  label: "M6",
                },
                {
                  id: "M6 AR",
                  label: "M6 AR",
                },
                {
                  id: "M6 AR-",
                  label: "M6 AR-",
                },
              ],
            },
            {
              id: "P2",
              label: "P2",
              children: [
                {
                  id: "M4",
                  label: "M4",
                },
                {
                  id: "M2",
                  label: "M2",
                },
                // {
                //   id: "L2",
                //   label: "L2",
                // },
                // {
                //   id: "L3",
                //   label: "L3",
                // },
                // {
                //   id: "L4",
                //   label: "L4",
                // },
                // {
                //   id: "L6",
                //   label: "L6",
                // },
                // {
                //   id: "L5",
                //   label: "L5",
                // },
                // {
                //   id: "L3+",
                //   label: "L3+",
                // },
              ],
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "M4-",
                  label: "M4-",
                },
                {
                  id: "M1",
                  label: "M1",
                },
                // {
                //   id: "L4-",
                //   label: "L4-",
                // },
                // {
                //   id: "L1",
                //   label: "L1",
                // },
              ],
            },
            {
              id: "ENROLLED",
              label: "ENROLLED",
              children: [
                {
                    id: "M7",
                    label: "M7",
                },
                {
                    id: "M7-",
                    label: "M7-",
                },
                {
                    id: "M7+",
                    label: "M7+",
                },
                {
                    id: "M7#",
                    label: "M7#",
                },
                {
                    id: "M7-JJ",
                    label: "M7-JJ",
                },
                {
                    id: "M7X",
                    label: "M7X",
                },
                {
                    id: "M7D1",
                    label: "M7D1",
                },
                {
                    id: "M7D2",
                    label: "M7D2",
                },
                {
                    id: "M8#1",
                    label: "M8#1"
                },
                {
                    id: "M8#1-",
                    label: "M8#1-"
                },
                {
                    id: "M8#1+",
                    label: "M8#1+"
                },
                {
                    id: "M8#2",
                    label: "M8#2"
                },
                {
                    id: "M8",
                    label: "M8"
                },
                {
                    id: "M8-",
                    label: "M8-",
                },
                {
                    id: "M8+",
                    label: "M8+"
                },
                {
                    id: "M8+1",
                    label: "M8+1"
                },
                {
                    id: "M8+2",
                    label: "M8+2"
                },
                {
                    id: "M8+3",
                    label: "M8+3"
                },
                {
                    id: "M8+4",
                    label: "M8+4"
                },
                {
                    id: "M8+5",
                    label: "M8+5"
                },
                {
                    id: "M8+6",
                    label: "M8+6"
                },
                {
                    id: "M9",
                    label: "M9",
                },
                {
                    id: "M9+1",
                    label: "M9+1",
                },
                {
                    id: "M9+2",
                    label: "M9+2",
                },
                {
                    id: "M9+3",
                    label: "M9+3",
                },
                {
                    id: "M9-",
                    label: "M9-",
                },
                {
                    id: "M10",
                    label: "M10",
                },
                {
                    id: "M10#",
                    label: "M10#",
                },
                {
                    id: "M11",
                    label: "M11",
                },
                {
                    id: "M11#",
                    label: "M11#",
                },
                {
                    id: "M12",
                    label: "M12",
                },
                {
                    id: "M7-IR",
                    label: "M7-IR",
                },
                {
                    id: "M7-INT",
                    label: "M7-INT",
                }
              ],
            },
            {
              id: "Dnd",
              label: "Dnd",
              children: [
                {
                  id: "N/A",
                  label: "N/A",
                },
                {
                  id: "DND",
                  label: "DND",
                },
              ],
            },
          ],
        },
      ],
      tablelinks: 0,
      currentTablePage: 1,
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      value6: null,
      value7: null,
      show_search_btn: true,
      operator: ["<", ">", "=", "<=", ">="],
      searchIn: {
        id: "",
        name: "",
      },
      rawBde: [],
      infosearch: [],
      searchCriteria: [],
      eligibility_code: null,
      bulkdata: [],
      count: 0,
      mwb_names: [],
      rawcities: [],
      SpocCityPrefilled: [],
      courses: [],
      filter_obj: {},
      filter_spoc_ids: [],
      for_notify: "",
      team: null,
      gm_spoc_ids: [],
      logged_in_user: [{ team: "", city: "", cc: "", user_tag: "", full_name: "" }],
      all_gm_spocs: []
    };
  },
  beforeDestroy() {
    EventBus.$emit("MWBtitlehide");
  },
  mounted() {
    const email = localStorage.getItem("email_id");
    this.user_email = email;
    this.getYearData();
    this.getAllQualifications();
    if (this.getTeam("GM")) {
      this.getGmReportingSpocs();
    } else {
      this.myprofile()
    }
    // if (!this.getTeam('GM') && !this.getTeam('CM')) {
    //   this.getEligibilityDetails(1)
    // }
  },
  watch: {
    initSelectedTeam: function (val) {
    this.initSelectedCity = [];
    this.initSelectedCC = [];
    this.initSelectedSpoc = [];
    this.initSelectedSC = [];
    this.cityData = [];
    this.selectedCCData = [];
    this.selectedSpocData = [];
    this.mergeAllSearch();
    if (this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'GM' && this.logged_in_user.team != 'ExEd' && this.logged_in_user.team != 'SR') {
      this.getSpocs(val);
    }
    this.teamData = [];
    this.teamData.push(val);
    },
    enrollment_dates(val) {
      if (val != null) {
        this.entrollmentDateRange = {
          startDate: null,
          endDate: null,
        }
      }
    },
    entrollmentDateRange(val) {
      if (val.startDate != null && val.endDate != null) {
        this.enrollment_dates = null;
      }
    },
    filter_spoc_ids(val) {
      console.log("filter_obj------", val, val.length)
    },
    initSelectedBDE: function () {
      this.mergeAllSearch();
    },
    initSelectedCity: function () {
      this.mergeAllSearch();
      if (this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'GM' && this.logged_in_user.team != 'ExEd' && this.logged_in_user.team != 'SR') {
        this.getOptionsData();
      }
    },
    initSelectedCC: function () {
      this.mergeAllSearch();
      if (this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'GM' && this.logged_in_user.team != 'ExEd' && this.logged_in_user.team != 'SR') {
        this.getOptionsData();
      }
    },
    initSelectedSpoc: function () {
      this.mergeAllSearch();
      if (this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'GM' && this.logged_in_user.team != 'ExEd' && this.logged_in_user.team != 'SR') {
        this.getOptionsData();
      }
    },
    initSelectedSC: function () {
      this.mergeAllSearch();
      if (this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'GM' && this.logged_in_user.team != 'ExEd' && this.logged_in_user.team != 'SR') {
        this.getOptionsData();
      }
    },
    initSelected: function () {
      this.mergeAllSearch();
    },
    initSelectedeligibiltiy: function () {
      this.mergeAllSearch();
    },
    initSelectedCourses: function () {
      this.mergeAllSearch();
    },
    initCourses: function (val) {
      this.mergeAllSearch();
      if (val.length != 0) {
        this.courses = [];
        if (val == "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            this.courses.push(child.label);
          });
        } else {
          this.courses.push(val);
        }
      } else {
        this.courses = [];
      }
    },
    initSelectedLevels: function () {
      this.mergeAllSearch();
    },
    currentTablePage: function (val) {
      this.getEligibilityDetails(val);
      console.log("val", val);
    },
    infosearch: function () {
      this.mergeAllSearch();
    },
    initSelectedReport: function () {
      this.mergeAllSearch();
    },
    // value6(val){
    //   if(val == null){
    //      this.show_search_btn = true;
    //   }
    // },
    value6(val) {
      console.log("value6", val, this.value7);
      if (this.value7 > 0 && val !== null && this.value7 !== null) {
        this.show_search_btn = false;
      } else if (val == null) {
        this.value7 = null;
      } else {
        this.show_search_btn = true;
      }
    },
    value7(val) {
      console.log("value7", this.value6, val);
      if (this.value6 !== null && val > 0 && val !== null) {
        this.show_search_btn = false;
      } else {
        this.show_search_btn = true;
      }
    },
    selected_eligibility_year(val) {
      this.eligibility_months = [];
      let current_year = moment().startOf("year").format("YYYY");
      if (val == current_year) {
        const d = new Date();
        const month = d.getMonth();
        for (let i = 0; i <= month; i++) {
          let name = this.months[i];
          this.eligibility_months.push(name);
        }
        // console.log("current Year", month);
      } else {
        // console.log("Years");
        this.eligibility_months = this.months;
      }
    },
    selected_ug: function () {
      this.mergeAllSearch();
    },
    selected_pg: function () {
      this.mergeAllSearch();
    },
    selected_indian_prof: function () {
      this.mergeAllSearch();
    },
    selected_global_prof: function () {
      this.mergeAllSearch();
    },
    select_pathway: function () {
      this.mergeAllSearch();
    }
  },
  methods: {
    downloadReport() {
      this.filter_spoc_ids = [];
      if (this.cityData.length != 0 ||
        this.selectedSCData.length != 0 ||
        this.selectedCCData.length != 0 ||
        this.selectedSpocData.length != 0) {
        this.getAllSpocId();
      }

      var year = "";
      var month = "";
      var enrollment_month = "";
      var enrollment_year = "";
      if (this.dates != null) {
        year = moment(this.dates).format("YY");
        month = moment(this.dates).format("MM");
      }
      if (this.enrollment_dates != null) {
        enrollment_year = moment(this.enrollment_dates).format("YYYY");
        enrollment_month = moment(this.enrollment_dates).format("MM");
      }
      var next_call_from = "";
      var next_call_to = "";
      if (
        this.nextCallDateRange.startDate !== null &&
        this.nextCallDateRange.endDate !== null
      ) {
        next_call_from = this.datatoTimestamp(this.nextCallDateRange.startDate);
        next_call_to = this.datatoTimestamp(this.nextCallDateRange.endDate);
      }
      var last_call_from = "";
      var last_call_to = "";
      if (
        this.lastCallDateateRange.startDate !== null &&
        this.lastCallDateateRange.endDate !== null
      ) {
        last_call_from = this.datatoTimestamp(
          this.lastCallDateateRange.startDate
        );
        last_call_to = this.datatoTimestamp(this.lastCallDateateRange.endDate);
      }
      var status_code = [];
      const eligibility_status_code = {
        "Not eligible": "1",
        "Eligible For CPA Exams": "2",
        "Eligible For CPA Exams & License": "3",
      };
      this.selectedEligibilityData.forEach((element) => {
        status_code.push(eligibility_status_code[element]);
      });
      const courses = this.courses.join();
      let selected_spoc = ""
      if (this.filter_spoc_ids.length > 0) {
        selected_spoc = this.filter_spoc_ids.join();
      }
      else if (this.for_notify == "empty") {
        selected_spoc = "";
      }
      let company = ""
      let email = ""
      let points = ""
      let mobile = ""
      let operator = ""
      let Can_id = ""
      let candidate_name = ""
      let eligibility_code = "";
      if (this.company != null) {
        company = this.company
      }
      if (this.email != null) {
        email = this.email
      }
      if (this.company != null) {
        company = this.company
      }
      if (this.value7 != null) {
        points = this.value7
      }
      if (this.contact_num != null) {
        mobile = this.contact_num
      }
      if (this.value6 != null) {
        operator = this.value6
      }
      if (this.eligibility_code != null) {
        eligibility_code = this.eligibility_code
      }
      if (this.Can_id != null) {
        Can_id = this.Can_id
      }
      if (this.candidate_name != null) {
        candidate_name = this.candidate_name
      }
      let enrollment_data_from_date = "";
      let enrollment_data_to_date = "";
      if (
        this.entrollmentDateRange.startDate !== null &&
        this.entrollmentDateRange.endDate !== null
      ) {
        enrollment_data_from_date = moment(this.entrollmentDateRange.startDate).format("YYYY-MM-DD")
        enrollment_data_to_date = moment(this.entrollmentDateRange.endDate).format("YYYY-MM-DD");
      }
      let obj = {
        user_email: this.user_email,
        company: company,
        course: courses,
        credit_points: points,
        eligibility_month: month,
        enrollment_from_date: enrollment_data_from_date,
        enrollment_to_date: enrollment_data_to_date,
        eligibility_status: status_code.join(),
        eligibility_year: year,
        enrollment_month: enrollment_month,
        enrollment_year: enrollment_year,
        email: email,
        last_call_from_date: last_call_from,
        next_call_to_date: next_call_to,
        next_call_from_date: next_call_from,
        spoc: selected_spoc,
        last_call_to_date: last_call_to,
        mobile: mobile,
        operator: operator,
        eligibility_code: eligibility_code,
        identity: Can_id,
        name: candidate_name,
      };
      let url = `${constants.MILES_SR}mwbEligibiltyExportCsv`;
      axios.get(url, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}`, }, }).then((response) => {
        this.download_report_success_popup = true;
        console.log("All_Data", response.data);
        EventBus.$emit("close-popup");
        setTimeout(() => this.download_report_success_popup = false, 4000)
      })
        .catch((error) => {
          if (error.response.status == 403) {
            this.$vs.notify({
              text: error.response.data.message,
              color: "danger",
            });
          } else {
            this.handleError(error);
          }
        });
    },
    myprofile() {
      let url = `${constants.SERVER_API}getUserProfile`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          let data = response.data.data;
          this.logged_in_user.team = data.team
          this.logged_in_user.city = data.city
          this.logged_in_user.cc = data.city_classification
          this.logged_in_user.user_tag = data.user_tag
          this.logged_in_user.full_name = data.full_name
          if (this.logged_in_user.team == 'ExEd') {
            this.initSelectedTeam = 'ExEd'
            this.team = false
            this.getSpocs('ExEd');
            this.treeDataTeam = ['ExEd']
          }
          else if (this.logged_in_user.team == 'CM') {
            this.initSelectedTeam = 'CM'
            this.team = true
            this.getSpocs('CM');
            this.treeDataTeam = ['CM']
          }
          else if (this.logged_in_user.team == 'SR') {
            this.initSelectedTeam = 'SR'
            this.getSpocs('SR');
            this.treeDataTeam = ['SR']
          }
          else if (localStorage.getItem('sub_team') === 'SR' && this.logged_in_user.team == 'GM') {
            this.initSelectedTeam = 'SR'
            this.teamData = [];
            this.teamData.push('SR');
            this.getSpocs('SR');
            this.treeDataTeam = ['SR']
          }
          else if (localStorage.getItem('sub_team') === 'CM' && this.logged_in_user.team == 'GM') {
            this.initSelectedTeam = 'CM'
            this.teamData = [];
            this.teamData.push('CM');
            this.getSpocs('CM');
            this.treeDataTeam = ['CM']
          }
          else {
            this.teamData = [];
            this.teamData.push('CM');
            this.getSpocs('CM');
            this.treeDataTeam = ['CM', 'SR']
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getGmReportingSpocs() {
      let gm_id = localStorage.getItem("spoc_id");
      let ids = [];
      let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          for (let i = 0; i < response.data.spocs.length; i++) {
            const element = response.data.spocs[i];
            if (!ids.includes(element.id)) {
              ids.push(element.id);
            }
          }
          this.gm_spoc_ids = response.data.spocs
          this.all_gm_spocs = ids
        })
        .catch((error) => {
          this.handleError(error);
        });
      this.myprofile()
    },
    getSpocs(val) {

    this.$vs.loading();
    let url;
    if (val == 'CM') {
      url = `https://milesdashboards.2x2.ninja/api/getSpocsBasedonCC`;
    } else if (val == 'ExEd') {
      url = `https://milesdashboards.2x2.ninja/api/getSpocsBasedonExEdTeam`;
    } else if (val == 'SR') {
      url = `https://milesdashboards.2x2.ninja/api/getSrTeamSpocs`;
    }
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.userAccessToken}`,
      },
    })
      .then((response) => {
        let city = localStorage.getItem("spoc_city")
        this.treeDataCity[0].children = [];
        this.treeDataSC[0].children = [];
        this.filter_data = response.data;
        const value = [...new Set(response.data.map((item) => item.city))];
        let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
        let duplicates = [];
        for (let i = 0; i < value.length; i++) {
          const element = value[i];
          const obj = {
            id: element,
            label: element,
          };
          this.cityData = [];
          if(city_options.includes(element)){
          if (this.logged_in_user.team == 'CM') {
            if (city.includes(element)) {
              this.treeDataCity[0].children.push(obj);
              this.initSelectedCity = ['All'];
              this.cityData.push(element);
            }
          }
          else if (this.logged_in_user.team == 'SR') {
            if (city.includes(element)) {
              this.treeDataCity[0].children.push(obj);
              this.initSelectedCity = ['All'];
              this.cityData.push(element);
            }
          }
          else if (this.logged_in_user.team == 'ExEd') {
            if (city.includes(element)) {
              this.treeDataCity[0].children.push(obj);
              this.initSelectedCity = ['All'];
              this.cityData.push(element);
            }
          }
          else if (this.logged_in_user.team == 'GM') {
            this.gm_spoc_ids.forEach(element1 => {
              if (element1.city == element && !duplicates.includes(element)) {
                this.treeDataCity[0].children.push(obj);
                this.initSelectedCity = ['All'];
                this.cityData.push(element);
                duplicates.push(element)
              }
            });
          } else {
            this.treeDataCC = [
              {
                id: "All",
                label: "All",
                children: [],
              },
            ]
            this.treeDataCity[0].children.push(obj);
          }
          }
        }
        this.treeDataCity[0].children.sort((a, b) => a.label.localeCompare(b.label));
        const CC = [
          ...new Set(response.data.map((item) => item.city_classification)),
        ];
        this.selectedCCData = [];
        CC.forEach((item) => {
          if (item != null) {
            const obj = {
              id: item,
              label: item,
            };
            if (this.logged_in_user.team == 'CM') {
              if (this.logged_in_user.cc == item) {
                this.treeDataCC[0].children.push(obj);
                this.initSelectedCC = ['All'];
                this.selectedCCData.push(item);
              }
            }
            else if (this.logged_in_user.team == 'SR') {
              if (this.logged_in_user.cc == item) {
                this.treeDataCC[0].children.push(obj);
                this.initSelectedCC = ['All'];
                this.selectedCCData.push(item);
              }
            }
            else if (this.logged_in_user.team == 'ExEd') {
              if (this.logged_in_user.cc == item) {
                this.treeDataCC[0].children.push(obj);
                this.initSelectedCC = ['All'];
                this.selectedCCData.push(item);
              }
            }
            else if (this.logged_in_user.team == 'GM') {
              this.gm_spoc_ids.forEach((element1) => {
                if (element1.city_classification == item && !duplicates.includes(item)) {
                  this.treeDataCC[0].children.push(obj);
                  this.initSelectedCC = ['All'];
                  this.selectedCCData.push(item);
                  duplicates.push(item)
                }
              });
            } else {
              this.treeDataSpoc = [
                {
                  id: "All",
                  label: "All",
                  children: [],
                },
              ]
              this.treeDataCC[0].children.push(obj);
            }
          }
        });
        this.treeDataCC[0].children.sort((a, b) => a.label.localeCompare(b.label));
        const spoc = [
          ...new Set(response.data.map((item) => item.spoc_name)),
        ];
        this.selectedSpocData = [];
        this.selected_default_spoc = [];
        spoc.forEach((item) => {
          if (item != null) {
            const obj = {
              id: item,
              label: item,
            };
            if (this.logged_in_user.team == 'CM') {
              if (this.logged_in_user.full_name == item) {
                this.treeDataSpoc[0].children.push(obj);
                this.initSelectedSpoc = ['All'];
                this.selectedSpocData.push(item);
                this.selected_default_spoc.push(item);
              }
            }
            else if (this.logged_in_user.team == 'SR') {
              if (this.logged_in_user.full_name == item) {
                this.treeDataSpoc[0].children.push(obj);
                this.initSelectedSpoc = ['All'];
                this.selectedSpocData.push(item);
                this.selected_default_spoc.push(item);
              }
            }
            else if (this.logged_in_user.team == 'ExEd') {
              if (this.logged_in_user.full_name == item) {
                this.treeDataSpoc[0].children.push(obj);
                this.initSelectedSpoc = ['All'];
                this.selectedSpocData.push(item);
                this.selected_default_spoc.push(item);
              }
            }
            else if (this.logged_in_user.team == 'GM') {
              this.gm_spoc_ids.forEach((element1) => {
                if (element1.full_name == item && !duplicates.includes(item)) {
                  this.treeDataSpoc[0].children.push(obj);
                  this.initSelectedSpoc = ['All']
                  this.selectedSpocData.push(item);
                  this.selected_default_spoc.push(item);
                  duplicates.push(item)
                }
              });
            } else {
              this.treeDataSpoc[0].children.push(obj);
            }
          }
        });
        this.treeDataSpoc[0].children.sort((a, b) => a.label.localeCompare(b.label));
        const spoc_classification = [
          ...new Set(response.data.map((item) => item.user_tag)),
        ];
        this.selectedSCData = [];
        spoc_classification.forEach((item) => {
          if (item != null) {
            const obj = {
              id: item,
              label: item,
            };
            if (this.logged_in_user.team == 'CM') {
              if (this.logged_in_user.user_tag == item) {
                this.treeDataSC[0].children.push(obj);
                this.initSelectedSC = ['All'];
                this.selectedSCData.push(item)
              }
            }
            else if (this.logged_in_user.team == 'SR') {
              if (this.logged_in_user.user_tag == item) {
                this.treeDataSC[0].children.push(obj);
                this.initSelectedSC = ['All'];
                this.selectedSCData.push(item)
              }
            }
            else if (this.logged_in_user.team == 'ExEd') {
              if (this.logged_in_user.user_tag == item) {
                this.treeDataSC[0].children.push(obj);
                this.initSelectedSC = ['All'];
                this.selectedSCData.push(item)
              }
            }
            else if (this.logged_in_user.team == 'GM') {
              this.gm_spoc_ids.forEach(element1 => {
                if (element1.user_tag == item && !duplicates.includes(item)) {
                  this.treeDataSC[0].children.push(obj);
                  this.initSelectedSC = ['All'];
                  duplicates.push(item)
                  this.selectedSCData.push(item);
                }
              });
            } else { this.treeDataSC[0].children.push(obj); }
          }
        });
        this.treeDataSC[0].children.sort((a, b) => a.label.localeCompare(b.label));
        // this.getMacRefferalData(1);
        this.getEligibilityDetails(1)
        this.$vs.loading.close();
      })
  .catch((error) => {
    this.$vs.loading.close();
    this.handleError(error);
  });
    },
    getOptionsData() {
      if (this.initSelectedCity.length != 0 && this.initSelectedCC.length == 0 && this.initSelectedSpoc.length == 0 && this.initSelectedSC.length == 0) {
        this.getUinqeCityData(this.filter_data);
        this.getCityWisesData();
      } else if (this.initSelectedCity.length == 0 && this.initSelectedCC.length != 0 && this.initSelectedSpoc.length == 0 && this.initSelectedSC.length == 0) {
        this.getCCData(this.filter_data);
        this.getCCWisesData();
      } else if (this.initSelectedCity.length == 0 && this.initSelectedCC.length == 0 && this.initSelectedSpoc.length != 0 && this.initSelectedSC.length == 0) {
        this.getSpocsData(this.filter_data);
        this.getSpocWisesData();
      } else if (this.initSelectedCity.length == 0 && this.initSelectedCC.length == 0 && this.initSelectedSpoc.length == 0 && this.initSelectedSC.length != 0) {
        this.getSCData(this.filter_data);
        this.getSCWisesData();
      } else if (this.initSelectedCity.length == 0 && this.initSelectedCC.length == 0 && this.initSelectedSpoc.length == 0 && this.initSelectedSC.length == 0) {
        this.getCCData(this.filter_data);
        this.getSpocsData(this.filter_data);
        this.getSCData(this.filter_data);
        this.getUinqeCityData(this.filter_data);
      }
    },
    getUinqeCityData(data) {
      this.treeDataCity[0].children = [];
      const cityData = [...new Set(data.map((item) => item.city))].sort();
      for (let i = 0; i < cityData.length; i++) {
        const element = cityData[i];
        const obj = {
          id: element,
          label: element,
        };
        this.treeDataCity[0].children.push(obj);
      }
    },
    getCCData(data) {
      this.treeDataCC[0].children = [];
      const cc_data = [...new Set(data.map((item) => item.city_classification))].sort();
      for (let i = 0; i < cc_data.length; i++) {
        const element = cc_data[i];
        if (element != null) {
          const obj = {
            id: element,
            label: element,
          };
          this.treeDataCC[0].children.push(obj);
        }
      }
    },
    getSpocsData(data) {
      this.treeDataSpoc[0].children = [];
      const spoc_data = [...new Set(data.map((item) => item.spoc_name))].sort();
      for (let i = 0; i < spoc_data.length; i++) {
        const element = spoc_data[i];
        if (element != null) {
          const obj = {
            id: element,
            label: element,
          };
          this.treeDataSpoc[0].children.push(obj);
        }
      }
    },
    getSCData(data) {
      this.treeDataSC[0].children = [];
      const sc_data = [...new Set(data.map((item) => item.user_tag))].sort();
      for (let i = 0; i < sc_data.length; i++) {
        const element = sc_data[i];
        if (element != null) {
          const obj = {
            id: element,
            label: element,
          };
          this.treeDataSC[0].children.push(obj);
        }
      }
    },
    getSpocWisesData() {
      const city_data = [];
      const CC_data = [];
      const SC_data = [];
      this.treeDataSC[0].children = [];
      this.treeDataCC[0].children = [];
      this.treeDataCity[0].children = [];
      this.filter_data.forEach((raw) => {
        this.selectedSpocData.forEach((spoc) => {
          if (raw.spoc_name == spoc) {
            city_data.push(raw.city);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.selectedSpocData.forEach((spoc) => {
          if (raw.spoc_name == spoc) {
            CC_data.push(raw.city_classification)
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.selectedSpocData.forEach((spoc) => {
          if (raw.spoc_name == spoc) {
            SC_data.push(raw.user_tag);
          }
        })
      });
      const city = [...new Set(city_data.map((item) => item))];
      for (let index = 0; index < city.length; index++) {
        const data = city[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCity[0].children.push(obj);
        }
      }
      const cc = [...new Set(CC_data.map((item) => item))];
      for (let index = 0; index < cc.length; index++) {
        const data = cc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCC[0].children.push(obj);
        }
      }
      const sc = [...new Set(SC_data.map((item) => item))];
      for (let index = 0; index < sc.length; index++) {
        const data = sc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSC[0].children.push(obj);
        }
      }
    },
    getCCWisesData() {
      const city_data = [];
      const spoc_data = [];
      const SC_data = [];
      this.treeDataSC[0].children = [];
      this.treeDataSpoc[0].children = [];
      this.treeDataCity[0].children = [];
      this.filter_data.forEach((raw) => {
        this.initSelectedCC.forEach((cc) => {
          if (raw.city_classification == cc) {
            city_data.push(raw.city);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.initSelectedCC.forEach((cc) => {
          if (raw.city_classification == cc) {
            spoc_data.push(raw.spoc_name);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.initSelectedCC.forEach((cc) => {
          if (raw.city_classification == cc) {
            SC_data.push(raw.user_tag);
          }
        });
      });
      const city = [...new Set(city_data.map((item) => item))];
      for (let index = 0; index < city.length; index++) {
        const data = city[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCity[0].children.push(obj);
        }
      }
      const spoc = [...new Set(spoc_data.map((item) => item))];
      for (let index = 0; index < spoc.length; index++) {
        const data = spoc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSpoc[0].children.push(obj);
        }
      }
      const sc = [...new Set(SC_data.map((item) => item))];
      for (let index = 0; index < sc.length; index++) {
        const data = sc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSC[0].children.push(obj);
        }
      }
    },
    getCityWisesData() {
      const cc_data = [];
      const spoc_data = [];
      const SC_data = [];
      this.treeDataSC[0].children = [];
      this.treeDataSpoc[0].children = [];
      this.treeDataCC[0].children = [];
      this.filter_data.forEach((raw) => {
        this.cityData.forEach((city) => {
          if (raw.city == city) {
            cc_data.push(raw.city_classification);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.cityData.forEach((city) => {
          if (raw.city == city) {
            spoc_data.push(raw.spoc_name);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.cityData.forEach((city) => {
          if (raw.city == city) {
            SC_data.push(raw.user_tag);
          }
        });
      });
      const cc = [...new Set(cc_data.map((item) => item))];
      for (let index = 0; index < cc.length; index++) {
        const data = cc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCC[0].children.push(obj);
        }
      }
      const spoc = [...new Set(spoc_data.map((item) => item))];
      for (let index = 0; index < spoc.length; index++) {
        const data = spoc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSpoc[0].children.push(obj);
        }
      }
      const sc = [...new Set(SC_data.map((item) => item))];
      for (let index = 0; index < sc.length; index++) {
        const data = sc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSC[0].children.push(obj);
        }
      }
    },
    getSCWisesData() {
      const cc_data = [];
      const spoc_data = [];
      const city_data = [];
      this.treeDataCity[0].children = [];
      this.treeDataSpoc[0].children = [];
      this.treeDataCC[0].children = [];
      this.filter_data.forEach((raw) => {
        this.selectedSCData.forEach((sc) => {
          if (raw.user_tag == sc) {
            city_data.push(raw.city);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.selectedSCData.forEach((sc) => {
          if (raw.user_tag == sc) {
            spoc_data.push(raw.spoc_name);
          }
        });
      });
      this.filter_data.forEach((raw) => {
        this.selectedSCData.forEach((sc) => {
          if (raw.user_tag == sc) {
            cc_data.push(raw.city_classification);
          }
        });
      });
      const city = [...new Set(city_data.map((item) => item))];
      for (let index = 0; index < city.length; index++) {
        const data = city[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCity[0].children.push(obj);
        }
      }
      const cc = [...new Set(cc_data.map((item) => item))];
      for (let index = 0; index < cc.length; index++) {
        const data = cc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataCC[0].children.push(obj);
        }
      }
      const spoc = [...new Set(spoc_data.map((item) => item))];
      for (let index = 0; index < spoc.length; index++) {
        const data = spoc[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSpoc[0].children.push(obj);
        }
      }
    },
    getAllQualifications() {
      this.$vs.loading();
      let url = `https://mfcomms.2x2.ninja/api/getAllQualifications`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("get All Qualification", response.data)
          for (let i = 0; i < response.data.ug.length; i++) {
            const element = response.data.ug[i];
            const obj = {
              id: element,
              label: element,
            };
            this.ug_options.push(obj);
          }
          for (let i = 0; i < response.data.pg.length; i++) {
            const element = response.data.pg[i];
            const obj = {
              id: element,
              label: element,
            };
            this.pg_options.push(obj);
          }
          for (
            let i = 0;
            i < response.data.indian_professional_qualification.length;
            i++
          ) {
            const element = response.data.indian_professional_qualification[i];
            const obj = {
              id: element,
              label: element,
            };
            this.indian_professional_qualifications.push(obj);
          }
          for (
            let i = 0;
            i < response.data.global_professional_qualification.length;
            i++
          ) {
            const element = response.data.global_professional_qualification[i];
            const obj = {
              id: element,
              label: element,
            };
            this.global_professional_qualifications.push(obj);
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    getAllSpocId() {
      if (this.selectedSpocData.length !== 0) {
        this.filter_spoc_ids = [];
        this.filter_data.forEach((raw) => {
          this.selectedSpocData.forEach((spoc) => {
            if (raw.spoc_name == spoc) {
              this.filter_spoc_ids.push(raw.id);
            }
          })
        });
      }else{
      let check_filters = false
      this.filter_spoc_ids = [];
      this.for_notify = "";
      console.log("selectedSCData", this.selectedSCData)
      this.filter_data.forEach((raw) => {
        if (
          this.cityData.length != 0 &&
          this.selectedCCData.length == 0 &&
          this.selectedSpocData.length !== 0 &&
          this.selectedSCData.length == 0) {
          this.cityData.forEach((city) => {
            this.selectedSpocData.forEach((spoc) => {
              if (raw.city == city && raw.full_name == spoc) {
                this.filter_spoc_ids.push(raw.id);
              }
            });
          });
        }
        else if (
          this.cityData.length != 0 &&
          this.selectedCCData.length == 0 &&
          this.selectedSpocData.length !== 0 &&
          this.selectedSCData.length != 0) {
          this.cityData.forEach((city) => {
            this.selectedSpocData.forEach((spoc) => {
              this.selectedSCData.forEach((sc) => {
                if (raw.user_tag == sc && raw.city == city && raw.full_name == spoc) {
                  this.filter_spoc_ids.push(raw.id);
                }
              })
            });
          });
        }
        else if (this.cityData.length == 0 &&
          this.selectedCCData.length == 0 &&
          this.selectedSCData.length == 0 &&
          this.selectedSpocData.length == 0) {
          this.filter_spoc_ids.push(raw.id);
        }
        else if (this.cityData.length == 0 &&
          this.selectedCCData.length == 0 &&
          this.selectedSCData.length !== 0 &&
          this.selectedSpocData.length !== 0) {
          check_filters = true
          this.selectedSpocData.forEach((spoc) => {
            this.selectedSCData.forEach((sc) => {
              if (raw.user_tag == sc && raw.full_name == spoc) {
                this.filter_spoc_ids.push(raw.id);
              }
            })
          });
        }
        else if (this.cityData.length == 0 &&
          this.selectedCCData.length == 0 &&
          this.selectedSCData.length !== 0 &&
          this.selectedSpocData.length == 0) {
          this.selectedSCData.forEach((sc) => {
            if (raw.user_tag == sc) {
              this.filter_spoc_ids.push(raw.id);
            }
          });
        }
        else if (this.cityData.length == 0 &&
          this.selectedCCData.length == 0 &&
          this.selectedSCData.length == 0 &&
          this.selectedSpocData.length !== 0) {
          this.selectedSpocData.forEach((spoc) => {
            if (raw.full_name == spoc) {
              this.filter_spoc_ids.push(raw.id);
            }
          });
        }
        else if (
          this.cityData.length != 0 &&
          this.selectedCCData.length == 0 &&
          this.selectedSCData.length == 0 &&
          this.selectedSpocData.length == 0
        ) {
          this.cityData.forEach((city) => {
            if (raw.city == city) {
              this.filter_spoc_ids.push(raw.id);
            }
          });
        } else if (
          this.cityData.length != 0 &&
          this.selectedSCData.length !== 0 &&
          this.selectedCCData.length == 0 &&
          this.selectedSpocData.length == 0
        ) {
          this.cityData.forEach((city) => {
            this.selectedSCData.forEach((sc) => {
              if (raw.city == city && raw.user_tag == sc) {
                this.filter_spoc_ids.push(raw.id);
                // console.log("spoc data", raw);
              } else {
                this.for_notify = "empty";
              }
            });
          });
        } else if (
          this.cityData.length != 0 &&
          this.selectedSCData.length !== 0 &&
          this.selectedCCData.length != 0 &&
          this.selectedSpocData.length == 0
        ) {
          this.cityData.forEach((city) => {
            this.selectedSCData.forEach((sc) => {
              this.selectedCCData.forEach((cc) => {
                if (raw.city == city && raw.user_tag == sc && raw.city_classification == cc) {
                  this.filter_spoc_ids.push(raw.id);
                } else {
                  this.for_notify = "empty";
                }
              })
            });
          });
        }
        else if (
          this.cityData.length != 0 &&
          this.selectedCCData.length != 0 &&
          this.selectedSpocData.length !== 0 &&
          this.selectedSCData.length == 0
        ) {
          this.cityData.forEach((city) => {
            this.selectedCCData.forEach((cc) => {
              this.selectedSpocData.forEach((spoc) => {
                if (
                  raw.city == city &&
                  raw.city_classification == cc &&
                  raw.full_name == spoc
                ) {
                  this.filter_spoc_ids.push(raw.id);
                }
              });
            });
          });
        }
        else if (
          this.cityData.length != 0 &&
          this.selectedCCData.length != 0 &&
          this.selectedSpocData.length == 0 &&
          this.selectedSCData.length == 0
        ) {
          this.cityData.forEach((city) => {
            this.selectedCCData.forEach((cc) => {
              if (
                raw.city == city &&
                raw.city_classification == cc
              ) {
                this.filter_spoc_ids.push(raw.id);
              }
            });
          });
        }
        else if (
          this.cityData.length != 0 &&
          this.selectedCCData.length != 0 &&
          this.selectedSpocData.length == 0 &&
          this.selectedSCData.length !== 0
        ) {
          this.cityData.forEach((city) => {
            this.selectedCCData.forEach((cc) => {
              this.selectedSCData.forEach((sc) => {
                if (
                  raw.city == city &&
                  raw.city_classification == cc &&
                  raw.user_tag == sc
                ) {
                  this.filter_spoc_ids.push(raw.id);
                }
                else {
                  this.for_notify = "empty";
                }
              })
            });
          });
        }
        else if (
          this.cityData.length != 0 &&
          this.selectedCCData.length != 0 &&
          this.selectedSCData.length !== 0 &&
          this.selectedSpocData.length !== 0
        ) {
          this.cityData.forEach((city) => {
            this.selectedCCData.forEach((cc) => {
              this.selectedSCData.forEach((sc) => {
                this.selectedSpocData.forEach((spoc) => {
                  if (
                    raw.city == city &&
                    raw.city_classification == cc &&
                    raw.user_tag == sc &&
                    raw.full_name == spoc
                  ) {
                    this.filter_spoc_ids.push(raw.id);
                  }
                  else {
                    this.for_notify = "empty";
                  }
                });
              });
            });
          });
        }
        else if (
          this.selectedSCData.length !== 0
        ) {
          this.selectedSCData.forEach((sc) => {
            if (
              raw.user_tag == sc
            ) {
              this.filter_spoc_ids.push(raw.id);
            }
          });
        }
        else if (
          this.selectedCCData.length !== 0
        ) {
          this.selectedCCData.forEach((sc) => {
            if (
              raw.city_classification == sc
            ) {
              this.filter_spoc_ids.push(raw.id);
            }
          });
        } 
        else if (this.logged_in_user.team == 'GM' && this.cityData.length == 0) {
          this.filter_spoc_ids = this.all_gm_spocs
        }
      });
      
      if (this.filter_spoc_ids.length == 0 && check_filters) {
        this.initSelectedSpoc = []
        this.initSelectedSC = []
        this.selectedSCData = []
        this.selectedSpocData = []
        this.$vs.notify({
          text: '0 spocs found for this filters',
          color: "danger",
        });
      }
      this.filter_spoc_ids = this.filter_spoc_ids.filter((item, index) => this.filter_spoc_ids.indexOf(item) === index);
      }
    },
    getEligibilityDetails(page) {
      this.filter_spoc_ids = [];
      this.getAllSpocId();
      var year = null;
      var month = null;
      var enrollment_month = null;
      var enrollment_year = null;
      if (this.dates != null) {
        year = moment(this.dates).format("MM");
        month = moment(this.dates).format("YYYY");
      }
      if (this.enrollment_dates != null) {
        enrollment_year = moment(this.enrollment_dates).format("YYYY");
        enrollment_month = moment(this.enrollment_dates).format("MM");
      }
      var next_call_from = null;
      var next_call_to = null;
      if (
        this.nextCallDateRange.startDate !== null &&
        this.nextCallDateRange.endDate !== null
      ) {
        next_call_from = this.datatoTimestamp(this.nextCallDateRange.startDate);
        next_call_to = this.datatoTimestamp(this.nextCallDateRange.endDate);
      }
      var last_call_from = null;
      var last_call_to = null;
      if (
        this.lastCallDateateRange.startDate !== null &&
        this.lastCallDateateRange.endDate !== null
      ) {
        last_call_from = this.datatoTimestamp(
          this.lastCallDateateRange.startDate
        );
        last_call_to = this.datatoTimestamp(this.lastCallDateateRange.endDate);
      }
      var enrollment_data_from_date = null;
      var enrollment_data_to_date = null;
      if (
        this.entrollmentDateRange.startDate !== null &&
        this.entrollmentDateRange.endDate !== null
      ) {
        enrollment_data_from_date = this.datatoTimestamp(
          this.entrollmentDateRange.startDate
        );
        enrollment_data_to_date = this.datatoTimestamp(this.entrollmentDateRange.endDate);
      }
      var status_code = [];
      const eligibility_status_code = {
        "Not eligible": "1",
        "Eligible For CPA Exams": "2",
        "Eligible For CPA Exams & License": "3",
      };
      this.selectedEligibilityData.forEach((element) => {
        status_code.push(eligibility_status_code[element]);
      });
      const courses = this.courses.join();
      let selected_spoc = null
      if (this.initSelectedCC.length != 0 || this.initSelectedCity.length != 0 || this.filter_spoc_ids.length > 0) {
        selected_spoc = this.filter_spoc_ids.join();
      }
      else if (this.for_notify == "empty") {
        selected_spoc = "";
      }
      // else if(this.for_notify == ""){
      //   selected_spoc = null;  
      // }
      this.filter_obj = {
        selected_team: this.teamData.join(),
        eligibility_status: status_code.join(),
        identity: this.Can_id,
        levels: this.selectedLevels.join(),
        enrollment_data_from_date: enrollment_data_from_date,
        enrollment_data_to_date: enrollment_data_to_date,
        name: this.candidate_name,
        company: this.company,
        mobile: this.contact_num,
        course: courses,
        email: this.email,
        enrollment_month: enrollment_month,
        enrollment_year: enrollment_year,
        spoc: selected_spoc,
        ug_qualification: this.selected_ug.join(),
        pg_qualification: this.selected_pg.join(),
        indian_professional_qualification: this.selected_indian_prof.join(),
        global_professional_qualification: this.selected_global_prof.join(),
        pathway: this.select_pathway.join(),
        last_call_from_date: last_call_from,
        last_call_to_date: last_call_to,
        next_call_from_date: next_call_from,
        next_call_to_date: next_call_to,
        eligibility_code: this.eligibility_code,
        eligibility_year: month,
        eligibility_month: year,
        operator: this.value6,
        credit_points: this.value7,
      };

      this.getMWBEligibility(page);
      console.log(page);
    },
    getMWBEligibility(page) {
      // console.log("filter_obj 2",this.filter_obj);
      this.$vs.loading();
      this.tabulatorDetails = [];
      let url = `https://miles-sr.2x2.ninja/api/MwbeligibilityFilter?page=${page}`;
      axios
        .get(url, {
          params: this.filter_obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("All_Data", response.data);
          this.tabulatorDetails = response.data.output_data.data;
          this.tablelinks = response.data.output_data.last_page;
          this.countsleads = response.data.output_data.total;
          this.currentTablePage = response.data.output_data.current_page;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          // this.handleError(error);
          this.tabulatorDetails = [];
          this.tablelinks = 0;
          this.countsleads = 0;
          this.currentTablePage = 1;
          if (error.response.status == 403) {
            this.$vs.notify({
              text: error.response.data.message,
              color: "danger",
            });
          } else {
            this.handleError(error);
          }

          // console.log("error",error.response.data.message)
        });
    },
    customFormatter(date) {
      return moment(date).format("MMM-YYYY");
    },
    refreshInputData() {
      this.Can_id = null;
      this.filter_obj = null;
      this.candidate_name = null;
      this.company = null;
      this.contact_num = null;
      this.courses = [];
      this.email = null;
      this.lastCallDateateRange = {
        startDate: null,
        endDate: null,
      };
      this.nextCallDateRange = {
        startDate: null,
        endDate: null,
      };
      this.value6 = null;
      this.value7 = null;
      this.dates = null;
      this.entrollmentDateRange = {
        startDate: null,
        endDate: null,
      };
      this.enrollment_dates = null,
        this.eligibility_code = null;
      this.tabulatorDetails = [];
      this.selectedSCData = [];
      this.selectedCourseData = [];
      this.selectedEligibilityData = [];
      this.initCourses = [];
      this.selectedLevels = [];
      this.initSelectedeligibiltiy = [];
      this.select_pathway = [];
      this.selected_global_prof = [];
      this.selected_indian_prof = [];
      this.selected_pg = [];
      this.selected_ug = [];
      this.selected_ug_data = [];
      this.selected_pg_data = [];
      this.selected_indian_data = [];
      this.selected_global_data = [];
      this.selected_pathway_data = [];
      if (this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'GM' && this.logged_in_user.team != 'ExEd' && this.logged_in_user.team != 'SR') {
        this.initSelectedTeam = 'CM'
        this.teamData = [];
        this.teamData.push('CM');
        this.initSelectedCity = [];
        this.initSelectedCC = [];
        this.initSelectedSpoc = [];
        this.initSelectedSC = [];
        this.cityData = [];
        this.selectedCCData = [];
        this.selectedSpocData = [];
        this.initSelectedLevels = [];
      }
      this.entrollmentDateRange = {
        startDate: null,
        endDate: null,
      };
      this.getYearData();
      this.getEligibilityDetails(1);
    },
    getCityClassification() {
      var classification = [];
      this.treeDataCC[0].children = [];
      this.filter_data.forEach((raw) => {
        this.cityData.forEach((city) => {
          if (raw.city == city) {
            // console.log("finddata", raw.city_classification, raw.city);
            classification.push(raw.city_classification);
          }
        });
      });
      const value = [...new Set(classification.map((item) => item))];
      let duplicates = []
      for (let index = 0; index < value.length; index++) {
        const data = value[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          if (this.logged_in_user.team == 'CM') {
            if (this.logged_in_user.cc == data) {
              this.treeDataCC[0].children.push(obj);
              this.initSelectedCC = ['All']
            }
          } else if (this.logged_in_user.team == 'GM') {
            this.gm_spoc_ids.forEach(element1 => {
              if (element1.city_classification == data && !duplicates.includes(data)) {
                this.treeDataCC[0].children.push(obj);
                this.initSelectedCC = ['All']
                duplicates.push(data)
              }
            });
          } else { this.treeDataCC[0].children.push(obj); }
        }
      }
    },
    getSpocDetails() {
      var spoc = [];
      this.treeDataSpoc[0].children = [];
      this.filter_data.forEach((raw) => {
        if (this.cityData.length != 0) {
          this.cityData.forEach((city) => {
            if (this.selectedCCData.length != 0) {
              this.selectedCCData.forEach((cc) => {
                if (raw.city == city && raw.city_classification == cc) {
                  let obj = {
                    city: raw.city_classification + ' - ' + raw.full_name,
                    data: raw.full_name,
                  }
                  spoc.push(obj);
                }
              });
            } else if (raw.city == city) {
              let obj = {
                city: raw.city_classification + ' - ' + raw.full_name,
                data: raw.full_name,
              }
              spoc.push(obj);
            }
          });
        } else {
          let obj = {
            city: raw.city_classification + ' - ' + raw.full_name,
            data: raw.full_name,
          }
          spoc.push(obj);
        }
      });
      console.log('oop', spoc);
      // const value = [...new Set(spoc.map((item) => item))];
      // console.log('oop',value);
      spoc = spoc.sort((a, b) => a.city.localeCompare(b.city));
      let duplicates = []
      spoc.forEach(item => {
        const data = item.data;
        if (data != null) {
          const obj = {
            id: data,
            label: item.city,
          };
          if (this.logged_in_user.team == 'CM') {
            if (this.logged_in_user.full_name == data) {
              this.treeDataSpoc[0].children.push(obj);
              // this.initSelectedSpoc = ['All']
              this.initSelectedSpoc = [this.treeDataSpoc[0].children[0].id]
            }
          } else if (this.logged_in_user.team == 'GM') {
            let gm_spocs = [];
            this.treeDataSpoc[0].children.forEach(child => {
              gm_spocs.push(child.id)
            });
            this.initSelectedSpoc = gm_spocs
            this.gm_spoc_ids.forEach(element1 => {
              if (element1.full_name == data && !duplicates.includes(data)) {
                this.treeDataSpoc[0].children.push(obj);
                // this.initSelectedSpoc = ['All']
                duplicates.push(data)
              }
            });
          } else { this.treeDataSpoc[0].children.push(obj); }
        }
      });
    },
    getSpocClassification(spoc) {
      this.treeDataSC[0].children = [];
      var sc = [];
      for (let i = 0; i < this.filter_data.length; i++) {
        const element = this.filter_data[i];
        for (let j = 0; j < spoc.length; j++) {
          const spoc_name = spoc[j];
          if (element.full_name === spoc_name) {
            sc.push(element.user_tag);
          }
        }
      }
      const value = [...new Set(sc.map((item) => item))];
      // console.log("all-city",value)
      for (let index = 0; index < value.length; index++) {
        const data = value[index];
        if (data != null) {
          const obj = {
            id: data,
            label: data,
          };
          this.treeDataSC[0].children.push(obj);
        }
      }
    },
    getYearData() {
      this.years = [];
      const starting_year = 2021;
      let current_year = moment().startOf("year").format("YYYY");
      let year_gap = Number(current_year) - Number(starting_year);
      let difference = 0;
      for (let i = 0; i <= year_gap; i++) {
        const num = difference++;
        const find_years = Number(current_year) - Number(num);
        this.years.push(find_years);
      }
      this.years.sort();
      // console.log("year", difference, this.years);
    },
    removechip(chip, name) {
      // let chipPlacement = '';
      console.log("chip", chip, name);
      if (name == "city") {
        if (this.initSelectedCity.indexOf(chip) === -1) {
          this.initSelectedCity = [];
          this.treeDataCity[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedCity.push(child.label);
              this.cityData.push(child.label);
            }
          });
        } else {
          this.initSelectedCity.splice(this.initSelectedCity.indexOf(chip), 1);
          this.cityData.splice(this.cityData.indexOf(chip), 1);
        }
      } else if (name == "cc") {
        if (this.initSelectedCC.indexOf(chip) === -1) {
          this.initSelectedCC = [];
          this.treeDataCC[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedCC.push(child.label);
              this.selectedCCData.push(child.label);
            }
          });
        } else {
          this.initSelectedCC.splice(this.initSelectedCC.indexOf(chip), 1);
          this.selectedCCData.splice(this.selectedCCData.indexOf(chip), 1);
        }
      } else if (name == "spoc") {
        if (this.initSelectedSpoc.indexOf(chip) === -1) {
          this.initSelectedSpoc = [];
          this.treeDataSpoc[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedSpoc.push(child.label);
              this.selectedSpocData.push(child.label);
            }
          });
        } else {
          this.initSelectedSpoc.splice(this.initSelectedSpoc.indexOf(chip), 1);
          this.selectedSpocData.splice(this.selectedSpocData.indexOf(chip), 1);
        }
      } else if (name == "sc") {
        if (this.initSelectedSC.indexOf(chip) === -1) {
          this.initSelectedSC = [];
          this.treeDataSC[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedSC.push(child.label);
              this.selectedSCData.push(child.label);
            }
          });
        } else {
          this.initSelectedSC.splice(this.initSelectedSC.indexOf(chip), 1);
          this.selectedSCData.splice(this.selectedSCData.indexOf(chip), 1);
        }
      } else if (name == "eligibility") {
        if (this.initSelectedeligibiltiy.indexOf(chip) === -1) {
          this.initSelectedeligibiltiy = [];
          this.treeDataeligibility[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedeligibiltiy.push(child.label);
              this.selectedEligibilityData.push(child.label);
            }
          });
        } else {
          this.initSelectedeligibiltiy.splice(
            this.initSelectedeligibiltiy.indexOf(chip),
            1
          );
          this.selectedEligibilityData.splice(
            this.selectedEligibilityData.indexOf(chip),
            1
          );
        }
      } else if (name == "course") {
        if (this.initCourses.indexOf(chip) === -1) {
          this.initCourses = [];
          this.treeDataCourses[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initCourses.push(child.label);
              this.selectedCourseData.push(child.label);
            }
          });
        } else {
          this.initCourses.splice(this.initCourses.indexOf(chip), 1);
          this.selectedCourseData.splice(
            this.selectedCourseData.indexOf(chip),
            1
          );
        }
      } else if (name == "levels") {
        if (this.initSelectedLevels.indexOf("All") == 0) {
          this.initSelectedLevels = [];
          this.treeDataLevels[0].children.forEach((child) => {
            child.children.forEach((chi) => {
              if (chi.label !== chip) {
                this.initSelectedLevels.push(chi.label);
                this.selectedLevels.push(chi.label);
              }
            });
          });
        } else if (this.initSelectedLevels.indexOf(chip) !== -1) {
          this.initSelectedLevels.splice(this.initSelectedLevels.indexOf(chip), 1);
          this.selectedLevels.splice(this.initSelectedLevels.indexOf(chip), 1);
        } else {
          let childProperty = "";
          this.treeDataLevels[0].children.forEach((child) => {
            if ("children" in child) {
              child.children.forEach((chi) => {
                if (chi.label === chip) {
                  childProperty = child.label;
                }
              });
            }
          });
          this.initSelectedLevels.splice(
            this.initSelectedLevels.indexOf(childProperty),
            1
          );
          this.selectedLevels.splice(
            this.initSelectedLevels.indexOf(childProperty),
            1);
          this.treeDataLevels[0].children.forEach((child) => {
            if (child.label === childProperty) {
              if ("children" in child) {
                child.children.forEach((chi) => {
                  if (chi.label !== chip) {
                    this.initSelectedLevels.push(chi.label);
                    this.selectedLevels.push(chi.label);
                  }
                });
              }
            }
          });
        }
      } else if (name == "ug") {
        this.selected_ug.splice(this.selected_ug.indexOf(chip), 1);
        this.selected_ug_data.splice(
          this.selected_ug_data.indexOf(chip),
          1
        );
      } else if (name == "pg") {
        this.selected_pg.splice(this.selected_pg.indexOf(chip), 1);
        this.selected_pg_data.splice(
          this.selected_pg_data.indexOf(chip),
          1
        );
      } else if (name == "indian_data") {
        this.selected_indian_prof.splice(this.selected_indian_prof.indexOf(chip), 1);
        this.selected_indian_data.splice(
          this.selected_indian_data.indexOf(chip),
          1
        );
      } else if (name == "global_data") {
        this.selected_global_prof.splice(this.selected_global_prof.indexOf(chip), 1);
        this.selected_global_data.splice(
          this.selected_global_data.indexOf(chip),
          1
        );
      } else if (name == "pathway") {
        this.select_pathway.splice(this.select_pathway.indexOf(chip), 1);
        this.selected_pathway_data.splice(
          this.selected_pathway_data.indexOf(chip),
          1
        );
      }
    },
    daterageclear() {
      this.dateRange.startDate = "";
      this.dateRange.endDate = "";
    },
    clearDateFilter() {
      this.lastCallDateateRange = {
        startDate: null,
        endDate: null,
      };
      this.nextCallDateRange = {
        startDate: null,
        endDate: null,
      };
      this.dates = null;
      this.enrollment_dates = null;
      this.entrollmentDateRange = {
        startDate: null,
        endDate: null,
      };
    },
    mergeAllSearch() {
      this.searchCriteria = [];
      this.initSelectedCourses.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.selectedEligibilityData = [];
      this.initSelectedeligibiltiy.forEach((sort) => {
        if (sort === "All") {
          this.treeDataeligibility[0].children.forEach((child) => {
            // this.searchCriteria.push(child.label);
            this.selectedEligibilityData.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.selectedEligibilityData.push(sort);
        }
      });
      this.initSelectedReport.forEach((sort) => {
        if (sort === "All") {
          this.treeDataReportSent[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      if (this.logged_in_user.team == 'CM') {
        this.teamData = [];
        this.teamData = this.treeDataTeam;
      }
      this.cityData = [];
      this.initSelectedCity.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCity[0].children.forEach((child) => {
            // this.searchCriteria.push(child.label);
            this.cityData.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.cityData.push(sort);
        }
      });
      this.selectedCCData = [];
      this.initSelectedCC.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCC[0].children.forEach((child) => {
            // this.searchCriteria.push(child.label);
            this.selectedCCData.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.selectedCCData.push(sort);
        }
      });
      this.selectedCourseData = [];
      this.initCourses.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            // this.searchCriteria.push(child.label);
            this.selectedCourseData.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.selectedCourseData.push(sort);
        }
      });
      this.selectedSpocData = [];
      this.initSelectedSpoc.forEach((sort) => {
        if (sort === "All") {
          this.treeDataSpoc[0].children.forEach((child) => {
            // this.searchCriteria.push(child.label);
            this.selectedSpocData.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.selectedSpocData.push(sort);
        }
      });
      this.selectedSCData = [];
      this.initSelectedSC.forEach((sort) => {
        if (sort === "All") {
          this.treeDataSC[0].children.forEach((child) => {
            this.selectedSCData.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.selectedSCData.push(sort);
        }
      });
      this.selectedLevels = [];
      this.initSelectedLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
          this.treeDataLevels[0].children[4].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
        } else if (sort === "ENROLLED") {
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
        } else if (sort === "Dnd") {
          this.treeDataLevels[0].children[4].children.forEach((child) => {
            this.selectedLevels.push(child.label);
          });
        } else {
          this.selectedLevels.push(sort);
        }
        console.log("selectedLevels", this.selectedLevels)
      });
      // this.searchCriteria.push("divider");
      // this.initSelectedeligibiltiy.forEach((sort) => {
      //   console.log(sort)
      //   // this.treeDataeligibility.forEach((child) => {
      //   //     console.log(child)
      //       this.searchCriteria.push(sort);
      //     // });
      //   // this.searchCriteria.push(this.initSelectedeligibiltiy);
      // });
      this.searchCriteria.push("divider");
      if (this.initSelected !== "" && this.initSelected !== undefined) {
        this.searchCriteria.push(this.initSelected);
      }
      this.searchCriteria.push("divider");
      this.infosearch.forEach((info) => {
        this.searchCriteria.push(info);
      });

      this.selected_ug_data = [];
      this.selected_ug.forEach((sort) => {
        // this.searchCriteria.push(sort);
        this.selected_ug_data.push(sort);
      });
      this.selected_pg_data = [];
      this.selected_pg.forEach((sort) => {
        // this.searchCriteria.push(sort);
        this.selected_pg_data.push(sort);
      });
      this.selected_indian_data = [];
      this.selected_indian_prof.forEach((sort) => {
        // this.searchCriteria.push(sort);
        this.selected_indian_data.push(sort);
      });
      this.selected_global_data = [];
      this.selected_global_prof.forEach((sort) => {
        // this.searchCriteria.push(sort);
        this.selected_global_data.push(sort);
      });
      this.selected_pathway_data = [];
      this.select_pathway.forEach((sort) => {
        // this.searchCriteria.push(sort);
        this.selected_pathway_data.push(sort);
      });
    },
    showTreeBox() {
      console.log("clicked");
      return this.treeDataBDE;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/dropdown.scss";

.download-report {
  .vs-popup {
    margin: 0px !important;
    width: 450px;
    background-color: #eee !important;
    padding: 30px;
    // transform: translate(-50%, -50%);
    border-radius: 10px;

    .vs-popup--header {
      border-radius: 10px !important;
      display: none;
    }

    .vs-popup--content {
      margin: 0px !important;
    }
  }
}

.popup-box>h5 {
  font-size: 22px;
  font-family: 'Montserrat';
  letter-spacing: 0.2px;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.popup-box>p {
  margin-top: 2px;
  font-size: 14px;
  font-family: 'Montserrat';
  letter-spacing: 0.25px;
  font-weight: 500;
  text-align: center;
}

.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}

.gap {
  margin-bottom: 4%;
}

.inicolor {
  color: gray;
}

.cardtext {
  color: gray;
  font-size: 20px;
}

.tree3 {
  // float: left;
  // width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}

.vs-dropdown--menu {
  width: 300px;
  // height: 500px;
  // overflow: hidden;
}

.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.credit_operator>.vs__dropdown-toggle {
  height: 32px !important;
  font-size: 15px;
  border-radius: 22px;
  border: 1px solid #0044bf;
  margin-top: -5px;
}

.credit_input>.vs-con-input .vs-input--input.normal {
  padding: 0.7rem;
  font-size: 1rem;
  border-radius: 22px !important;
  border: 1px solid #0044bf !important;
}

.gap {
  margin-right: 1%;
}

.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

i {
  font-size: 18px;
}

.vl {
  border-left: 1px solid black !important;
  height: 30px !important;
}

.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}

#style-1::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrollbar {
  overflow-y: scroll;
}

.desc-border-box p {
  display: flex;
  justify-content: space-between;
  padding-inline: 20px;
  align-items: center;
  background-color: #0044ba;
  border-radius: 15px 0px;
  width: 233.62px;
  height: 37.8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
}
.mwb-filter-card .vx-card__collapsible-content .vx-card__body {
    padding: 0px !important;
}
.mwb-filter-card {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.options-card {
  border-radius: 15px;
  background-color: #0044ba;
}

.tree-drop {
  margin-top: 0.8%;
  padding: 0;
}

.tree-drop#city>.vs-con-dropdown {
  background: #edebf3;
  border: 1px solid #2e0d92;
  width: 90%;
  border-radius: 10px;
  margin-inline: 2%;
}

.tree-drop#spoc>.vs-con-dropdown {
  background: rgba(188, 235, 250, 0.69);
  border: 1px solid #146d8a;
}

.tree-drop#course>.vs-con-dropdown {
  background: rgba(255, 194, 194, 0.69);
  border: 1px solid #c42e2e;
  border-radius: 10px;
}

.tree-drop#elgibility_status>.vs-con-dropdown {
  background: #f1ad78;
  border: 1px solid #F38630;
  border-radius: 10px;
}

.tree-drop#levels>.vs-con-dropdown {
  background: #F5E1CE;
  border: 1px solid #F1A964;
  border-radius: 10px;
}

.tree-drop#cc>.vs-con-dropdown {
  background: #fae1fe;
  border: 1px solid #de56f4;
  width: 90%;
  border-radius: 10px;
  margin-inline: 2%;
}
.tree-drop#team>.vs-con-dropdown {
  background: #caecc5;
  border: 1px solid #96e08b;
  width: 90%;
  border-radius: 10px;
  margin-inline: 2%;
}

.tree-drop#sc>.vs-con-dropdown {
  background: #b4edff;
  border: 1px solid #13a0cd;
  width: 90%;
  border-radius: 10px;
  margin-inline: 2%;
}

.tree-drop#card2>.vs-con-dropdown {
  border: 1px solid #0044ba;
  width: 280.89px;
  height: 44.47px;
  display: flex;
  border-radius: 15px;
  align-items: center;
  padding-inline: 10px;
}

.tree-drop>.vs-con-dropdown {
  width: 100%;
  min-height: 32px;
  border-radius: 10px;
  // padding: 5.8px;
  padding-inline: 5px;
  // padding-top:3px;
  color: #000000;
  display: flex !important;
  align-items: center;
}

.a-iconn {
  color: #000;
  font-weight: 400;
  font-size: 12px;
  width: 99%;
  display: flex;
  justify-content: flex-end !important;
}

.a-iconn>.material-icons {
  margin-left: auto;
}

.no-padd {
  padding: 0% !important;
}

.labell {
  z-index: 1000;
  position: inherit;
  width: 200px;
  // left: 27%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  padding-inline-start: 10px;
}

.my_date_picker>div input {
  background: #ffffff;
  -webkit-padding-start: 9%;
  display: block;
  height: 44px;
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  // max-width:180px;
  width: 100%;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
  color: #000000 !important;
  border-radius: 10px;
}

.criteria {
  background: #ffffff;
  min-height: 119.65px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.criteria_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

// .daterangee2>.reportrange-text {
//   width: 207px !important;
// }
.daterangee>.reportrange-text {
  display: inline-flex;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  height: 44px;
  width: 100%;
}

.daterangee>.reportrange-text>span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.input-filter>.vs-con-input>.vs-inputx {
  height: 32px;
  margin-top: 5px;
  border: 1px solid #0044ba !important;
  border-radius: 15px;
}

.input-filter .vs-con-input .input-span-placeholder {
  font-size: 14px;
  margin-left: 1%;
}

.input-filter {
  width: 100% !important;
}

.ele-side-head {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}

.option-side-head {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.ele-inside-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding-inline-start: 10px;
}

.ele-inside-text>i {
  color: rgba(0, 0, 0, 0.4);
}

.criteria_chip {
  border-radius: 5px;
}

.criteria_chip .vs-chip--close {
  background: transparent !important;
  color: #000000;
}

.criteria_chip .vs-chip--text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #000000;
}

.eligibility-inside-text>i {
  color: rgba(0, 0, 0, 0.4);
}

.eligibility-inside-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.none_padding {
  padding: 0px;
}

.filter {
  margin-left: 4px;
  // margin-top: 2%;
}

.visit_date_picker>div input {
  background: #ffffff;
  -webkit-padding-start: 9%;
  display: block;
  border-radius: 5px;
  min-height: 44.47px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
  color: #000000 !important;
  border-radius: 10px;
}

.vs-input--input.hasIcon.icon-after-input+.vs-input--placeholder {
  padding-left: 1.85em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 24px; */
  /* display: flex; */
  /* align-items: center; */
  color: rgba(0, 0, 0, 0.4);
}

.date_filter_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.eligibility>.reportrange-text {
  background: #ffffff;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 16%);
  min-height: 44.47px;
  border-radius: 10px;
}

.qualification .vs-dropdown--menu {
  width: 233px !important;
}

.pathway_dropdown .vs-dropdown--menu {
  width: 250px !important;
}

// @media only screen and (min-width: 1800px) {
//   .filter {
//     margin-left: 2.8%;
//   }
// }
// @media only screen and (min-width: 1725px) {
//   .filter {
//     margin-left: 1.4%;
//   }
// }
@media screen and (min-width: 900px) and (max-width: 1400px) {
  .tree-drop>.vs-con-dropdown {
    width: 125%;
    min-height: 32px;
    border-radius: 10px;
    padding-inline: 5px;
    color: #000000;
    display: flex !important;
    align-items: center;
  }

  .daterangee>.reportrange-text {
    width: 81%;
  }

  .labell {
    font-size: 10px;
  }

  .my_date_picker>div input {
    width: 95%;
  }
}

@media only screen and (max-width: 1450px) {
  .date_filter_heading {
    font-size: 9px
  }
}

// @media only screen and (max-width: 1680px) {
//   .daterangee>.reportrange-text {
//     width: 148px;
//   }
//   .daterangee>.reportrange-text>span {
//     font-size: 10px;
//   }
//   .daterangee2>.reportrange-text {
//     width: 94% !important;
//   }
// }
// @media only screen and (max-width: 1460px) {
//   .daterangee>.reportrange-text {
//     width: 128px;
//   }
//   .daterangee>.reportrange-text>span {
//     font-size: 9px;
//   }
// }
</style>