<template>
  <div>
    
    <vx-card class="tabulator_card">
       <!-- v-if="user_id == 132 || user_id == 937 || user_id == 12" -->
       <vs-row class="mb-3">
        <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start">
          <treeselect
                      v-model="selected_column"
                      :multiple="true"
                      :options="treeDataHide"
                      placeholder="Hide Column"
                    />
        </vs-col>
        <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start" vs-align="center">
          <vs-button color="#0044BA"
          size="small" @click="HideColumn">
            Hide - Unhide Columns
          </vs-button>
        </vs-col>
        <vs-col vs-w="3" vs-offset="3" vs-type="flex" vs-justify="flex-end" vs-align="center">
          <vs-button
          color="#0044BA"
          icon="file_download"
          size="small"
          @click="downloadCvsTable"
          ref="loadableButton"
          id="button-with-loading"
          class="vs-con-loading__container"
          v-if="show_btn"
          >Download</vs-button
        >
        </vs-col>
       </vs-row>
      <div
        style="display: flex; justify-content: end"
        class="mb-2"
      >
        
      </div>
      <div ref="table1" id="example-table-theme"></div>
    </vx-card>
  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
import eventbus from '../components/eventbus';
import Treeselect from "@riophae/vue-treeselect";
export default {
  components: {
    Treeselect,
  },
  props: ["tabulatorDetails"],
  created(){
    eventbus.$on("close-popup", () => {
      this.$vs.loading.close(this.$refs.loadableButton.$el)
    });
  },
  mounted() {
    this.hideDownload()
    console.log("tableData", this.tabulatorDetails);
    this.getEligibilityTable();
    for (let i = 0; i < this.tabulatorDetails.length; i++) {
      const element = this.tabulatorDetails[i];
      const report_status_code = {
        0: "Not Sent",
        1: "Sent",
      };
      element.otb_game_plan_eligibility_data.is_eligibilityreport_sent =
        report_status_code[
          element.otb_game_plan_eligibility_data.is_eligibilityreport_sent
        ];
      const eligibility_status = {
        1: "Not eligible",
        2: "Eligible For CPA Exams",
        3: "Eligible For CPA Exams & License",
      };
      element.otb_game_plan_eligibility_data.eligibility_status =
        eligibility_status[
          element.otb_game_plan_eligibility_data.eligibility_status
        ];
      Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
    }
    this.user_id = localStorage.getItem("user_id");
  },
  watch: {
    tabulatorDetails(val) {
      this.MwbLeads = val;
      this.getEligibilityTable();
      for (let i = 0; i < this.MwbLeads.length; i++) {
        const element = this.MwbLeads[i];
        const report_status_code = {
          0: "Not Sent",
          1: "Sent",
        };
        element.otb_game_plan_eligibility_data.is_eligibilityreport_sent =
          report_status_code[
            element.otb_game_plan_eligibility_data.is_eligibilityreport_sent
          ];
        const eligibility_status = {
          1: "Not eligible",
          2: "Eligible For CPA Exams",
          3: "Eligible For CPA Exams & License",
        };
        element.otb_game_plan_eligibility_data.eligibility_status =
          eligibility_status[
            element.otb_game_plan_eligibility_data.eligibility_status
          ];
        Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
      }
    },
  },
  data() {
    return {
      show_btn:false,
      backgroundLoading:'primary',
      colorLoading:'#fff',
      MwbLeads: [],
      user_id: "",
      treeDataHide:[
        {
          id:"company",
          label:"Company"
        },
        {
          id:"experience_int",
          label:"Experience"
        },
        {
          id:"education_tags",
          label:"Education"
        },
        {
          id:"education_tag_data.pg",
          label:"PG Qualification"
        },
        {
          id:"education_tag_data.ug",
          label:"UG Qualification"
        },
        {
          id:"education_tag_data.indian",
          label:"Indian Qualification"
        },
        {
          id:"education_tag_data.global",
          label:"Gobal Qualification"
        },
        {
          id:"pathway",
          label:"Pathway"
        },
        
        {
          id:"level",
          label:"Level"
        },
        {
          id:"enrollment_date",
          label:"Enrollment Date"
        },

        {
          id:"courses",
          label:"Course"
        },
        {
          id:"spoc_name",
          label:"CM SPOC"
        },
        {
          id:"sr_name",
          label:"SR SPO"
        },
        {
          id:"otb_game_plan_eligibility_data.eligibility_status",
          label:"ELIGIBILITY STATUS"
        },
        {
          id:"otb_game_plan_eligibility_data.total_credits",
          label:"Credits"
        },
        {
          id:"eligibility_requested_date",
          label:"Eligibility Requested Date"
        },
        {
          id:"eligibility_received_date",
          label:"Eligibility Received Date"
        },
        {
          id:"lead_created_date",
          label:"Lead Created Date"
        },
        {
          id:"last_call_date",
          label:"Last Call"
        },
        {
          id:"next_call_date",
          label:"Next Call"
        },
         {
          id:"sr_last_call_date",
          label:"SR Last Call"
        },
         {
          id:"sr_next_call_date",
          label:"SR Next Call"
        },
         {
          id:"mhp_date",
          label:"MHP Date"
        },
      ],
      selected_column:[],
    };
  },
  methods: {
    hideDownload(){
      const id = localStorage.getItem('user_id')
      if (id == 39 || id == 132 || id == 937 || id == 122) {
        this.show_btn = true
      }
    },
    getEligibilityTable() {
      this.tabulator = new Tabulator(this.$refs.table1, {
        maxHeight: "80vh",
        data: this.MwbLeads,
        rowFormatter: (function(row) {
          var evenColor = "#F5F6FA";
          var oddColor = "#FFF";
          row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
          }),
        columns: [
       
          {
            title: "Can-ID",
            field: "identity",
            frozen: true,
            sorter: "alphanum",
          },
          {
            title: "Candidate Name",
            field: "person_name",
            frozen: true,
          },
          {
            title: "Company",
            field: "company",
          },
          {
            title: "Experience",
            field: "experience_int",
          },
          {
            title: "Education",
            field: "education_tags",
          },
          {
            title:"PG Qualification",
            field: "education_tag_data.pg",
          },
          {
            title:"UG Qualification",
            field: "education_tag_data.ug",
          },
          {
            title:"Indian Qualification",
            field: "education_tag_data.indian",
          },
          {
            title:"Gobal Qualification",
            field: "education_tag_data.global",
          },
          {
            title:"Pathway",
            field: "pathway",
          },
          {
            title: "Level",
            field: "level",
            sorter: "alphanum",
          },
          {
            title: "Enrollment Date",
            field: "enrollment_date",
            headerSort: false 
          },
          {
            title: "Course",
            field: "courses",
          },
          {
            title: "CM SPOC",
            field: "spoc_name",
          },
          {
            title: "SR SPOC",
            field: "sr_name",
          },
          {
            title: "ELIGIBILITY STATUS",
            field: "otb_game_plan_eligibility_data.eligibility_status",
          },
          {
            title: "Credits",
            field: "otb_game_plan_eligibility_data.total_credits",
          },
          {
            title: "Eligibility Requested Date",
            field: "eligibility_requested_date",
            headerSort: false 
          },
          {
            title: "Eligibility Received Date",
            field: "eligibility_received_date",
            headerSort: false 
          },
          {
            title: "Lead Created Date",
            field: "lead_created_date",
            headerSort: false 
          },
          {
            title: "Last Call",
            field: "last_call_date",
            headerSort: false 
          },
          {
            title: "Next Call",
            field: "next_call_date",
            headerSort: false 
          },
          {
            title: "SR Last Call",
            field: "sr_last_call_date",
            headerSort: false 
          },
          {
            title: "SR Next Call",
            field: "sr_next_call_date",
            headerSort: false 
          },
          {
            title: "MHP Date",
            field: "mhp_date",
            headerSort: false 
          },
           {
            title: "About",
            field: "info",
            formatter: "image",
            frozen: true,
            headerSort: false,
            hozAlign:"center",
            formatterParams: {
                height: "25px",
                width: "35px",
            },
            cssClass: "padded-cell",
            cellClick: this.getId,
          },
        ],
      });
     
    },
    HideColumn(){
      this.treeDataHide.forEach((treeData) => {
           this.tabulator.showColumn(treeData.id)
      });
      if(this.selected_column.length > 0){
        this.selected_column.forEach((selected_item)=>{
          this.treeDataHide.forEach((item) =>{
              if(item.id == selected_item){
                this.tabulator.hideColumn(item.id)
              }
            })
          })
      }
    },
    getId(e, cell) {
      console.log(e, cell._cell.row.data.id);
      this.openBigPopup(cell._cell.row.data.id);
    },
    downloadCvsTable() {
      this.$vs.loading({
        background: "#0044BA",
        color: "white",
        container: this.$refs.loadableButton.$el,
        scale: 0.45
      });
      this.$emit("download-eligibility-report")
    },
  },
};
</script>

<style>
.tabulator_card{
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}
.tabulator {
  background-color: #f2f2f2;
}

.tabulator .tabulator-header {
  background-color: #f2f2f2;
  /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(odd) {
  background-color: #f2f2f2;
  color: rgb(0, 0, 0) !important;
  /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(even) {
  background-color: #e4e1e1;
  color: rgb(0, 0, 0) !important;
  /* padding-block: 5px; */
}

.tabulator .tabulator-header .tabulator-col {
  text-align: center;
}
.padded-cell img{
  padding-inline-start: 10px;
}
.tabulator-calcs-bottom .tabulator-cell {
  background-color: rgb(0, 0, 0);
  color: #f2f2f2 !important;
}
</style>